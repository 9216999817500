import React from 'react'
import { Link } from 'gatsby'
import { VscCallIncoming, VscMailRead } from 'react-icons/vsc'

interface FooterProps {
  theme: string
}

const Footer: React.FC<FooterProps> = ({ theme }) => {
  return (
    <footer className="bg-gray-50 dark:bg-gray-800 border-t dark:border-t-gray-600">
      <div className="max-w-6xl px-6 py-12 mx-auto flex flex-col items-center justify-center md:items-start md:justify-between md:flex-row space-y-10 md:space-y-0 text-gray-600 dark:text-gray-300 text-sm text-center md:text-left">
        <div className="w-full md:w-1/3">
          <Link to="/">
            {theme === 'dark' ? (
              <img src="/casadalmine_bianco.svg" className="w-40 mx-auto md:mx-0" alt="Casa Dalmine" />
            ) : (
              <img src="/casadalmine.svg" className="w-40 mx-auto md:mx-0" alt="Casa Dalmine" />
            )}
          </Link>
          <div className="mt-4">Via&nbsp;Manzoni,&nbsp;55</div>
          <div className="mb-2">24044&nbsp;Dalmine&nbsp;(BG)</div>

          <a
            href="tel:+3903556433"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center md:justify-start space-x-3 hover:text-black dark:hover:text-white"
          >
            <VscCallIncoming className="text-red-600 dark:text-red-400" />
            <span>035&nbsp;564533</span>
          </a>

          <a
            href="mailto:info@casadalmine.it"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center md:justify-start space-x-3 hover:text-black dark:hover:text-white"
          >
            <VscMailRead className="text-red-600 dark:text-red-400" />
            <span>info@casadalmine.it</span>
          </a>
        </div>

        <div className="w-full md:w-1/3">
          <h4 className="text-base text-gray-800 dark:text-gray-100 mb-4 font-semibold">Menù</h4>
          <div className="mb-1">
            <Link to="/agenzia" className="hover:text-black dark:hover:text-white">
              Agenzia
            </Link>
          </div>
          <div className="mb-1">
            <Link to="/valuta-il-tuo-immobile" className="hover:text-black dark:hover:text-white">
              Valuta immobile
            </Link>
          </div>
          <div className="mb-1">
            <Link to="/servizi" className="hover:text-black dark:hover:text-white">
              Servizi
            </Link>
          </div>
          <div className="mb-1">
            <Link to="/contatti" className="hover:text-black dark:hover:text-white">
              Contatti
            </Link>
          </div>
        </div>

        <div className="w-full md:w-1/3">
          <h4 className="text-base text-gray-800 dark:text-gray-100 mb-4 font-semibold">Note legali</h4>
          <div className="mb-2">
            <Link to="/informativa-privacy" className="hover:text-black dark:hover:text-white">
              Informativa Privacy
            </Link>
          </div>
          <div className="mb-2 text-xs">P.IVA&nbsp;02226300164</div>
          <div className="mb-2 text-xs mx-auto md:mx-0">Capitale sociale: € 26.000 i.v.</div>
          <div className="max-w-xs text-xs mx-auto md:mx-0">
            La società ha ricevuto nel corso del 2020 aiuti di stato pubblicati sul RNA sezione Trasparenza.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
