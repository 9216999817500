import React, { useState } from 'react'

import Header from './header'
import Footer from './footer'
import clsx from 'clsx'

interface LayoutProps {
  servizi: { value: string; label: string }[]
}

const getTheme = () => {
  const theme = typeof window === 'undefined' ? '' : localStorage.getItem('theme')
  return theme
}

const Layout: React.FC<LayoutProps> = ({ children, servizi }) => {
  const [theme, setTheme] = useState(getTheme() || 'light')
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
    localStorage.setItem('theme', newTheme)
  }

  return (
    <div
      className={clsx(
        'min-h-screen flex flex-col justify-between font-content antialiased font-paragraph',
        getTheme() === 'dark' ? 'dark bg-gray-900' : 'light bg-white'
      )}
    >
      <Header servizi={servizi} theme={getTheme()} toggleTheme={toggleTheme} />
      <main className="mb-auto isolate">{children}</main>
      <Footer theme={getTheme()} />
    </div>
  )
}

export default Layout
