import React from 'react'
import { Link } from 'gatsby'
import { Popover, Transition } from '@headlessui/react'
import { VscCallIncoming, VscMailRead, VscChevronDown, VscMenu } from 'react-icons/vsc'
import { BsMoonFill, BsSunFill } from 'react-icons/bs'

interface HeaderProps {
  servizi: { value: string; label: string }[]
  theme: string
  toggleTheme: () => void
}

const Header: React.FC<HeaderProps> = ({ servizi, theme, toggleTheme }) => {
  return (
    <header className="bg-white dark:bg-gray-900 sm:sticky top-0 z-10">
      <div className="max-w-7xl mx-auto px-6">
        <div className="w-full flex flex-row justify-between items-center py-4">
          <Link to="/">
            {theme === 'dark' ? (
              <img src="/casadalmine_bianco.svg" className="w-52 md:w-60" alt="Casa Dalmine" />
            ) : (
              <img src="/casadalmine.svg" className="w-52 md:w-60" alt="Casa Dalmine" />
            )}
          </Link>

          <div className="flex items-center justify-between sm:justify-end sm:flex-row-reverse">
            {/* DARK MODE BUTTON */}
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 mr-4 sm:mr-0 sm:ml-4 md:ml-8 text-gray-400 dark:text-gray-200"
              onClick={toggleTheme}
            >
              {theme === 'dark' ? <BsSunFill className="text-xl" /> : <BsMoonFill />}
            </button>

            {/* MOBILE MENU */}
            <div className="sm:hidden">
              <Popover className="relative z-50">
                <Popover.Button className="p-2 text-3xl text-red-600">
                  <VscMenu />
                </Popover.Button>

                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="flex right-0 flex-col absolute mt-2 z-10 bg-white rounded shadow-xl w-80 border">
                    <Link
                      to="/"
                      className="px-6 py-3 text-gray-800 hover:bg-gray-50 border-y border-transparent hover:border-gray-200 transition-colors"
                    >
                      Home
                    </Link>
                    <Link
                      to="/agenzia"
                      className="px-6 py-3 text-gray-800 hover:bg-gray-50 border-y border-transparent hover:border-gray-200 transition-colors"
                    >
                      Agenzia
                    </Link>
                    <Link
                      to="/valuta-il-tuo-immobile"
                      className="px-6 py-3 text-gray-800 hover:bg-gray-50 border-y border-transparent hover:border-gray-200 transition-colors"
                    >
                      Valuta la tua casa
                    </Link>
                    {servizi && servizi.length ? (
                      <Popover className="relative z-10">
                        <Popover.Button className="flex w-full items-center space-x-1 px-6 py-3 text-gray-800 hover:bg-gray-50 border-y border-transparent hover:border-gray-200 transition-colors">
                          <span>Servizi</span>
                          <VscChevronDown />
                        </Popover.Button>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute flex flex-col space-y-2 mt-2 py-3 px-6 z-10 bg-white shadow-xl w-max border rounded-md">
                            {servizi.map((servizio) => (
                              <Link
                                key={servizio.value}
                                className="block text-sm font-medium text-gray-600 hover:text-blue-900 transition-colors"
                                to={`/servizi/${servizio.value}`}
                              >
                                {servizio.label}
                              </Link>
                            ))}
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    ) : (
                      <Link
                        to="/servizi"
                        className="px-6 py-3 text-gray-800 hover:bg-gray-50 border-y border-transparent hover:border-gray-200 transition-colors"
                      >
                        Servizi
                      </Link>
                    )}
                    <Link
                      to="/contatti"
                      className="px-6 py-3 text-gray-800 hover:bg-gray-50 border-y border-transparent hover:border-gray-200 transition-colors"
                    >
                      Contatti
                    </Link>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>

            {/* TEL EMAIL */}
            <div className="hidden sm:flex space-x-6 lg:space-x-14 mt-5 sm:mt-0">
              <div className="flex items-center space-x-2 md:space-x-4">
                <VscCallIncoming className="text-gray-700 dark:text-gray-300 md:text-red-600 dark:md:text-red-400 text-2xl md:text-4xl" />
                <div>
                  <div className="hidden md:block text-sm text-gray-900 dark:text-gray-200">Chiama il</div>
                  <a
                    className="block text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-500 transition-colors text-sm md:text-md font-semibold"
                    href="tel:+3903556433"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    035 564 533
                  </a>
                </div>
              </div>
              <div className="flex items-center space-x-2 md:space-x-4">
                <VscMailRead className="text-gray-700 dark:text-gray-300 md:text-red-600 dark:md:text-red-400 text-2xl md:text-4xl" />
                <div>
                  <div className="hidden md:block text-sm text-gray-900 dark:text-gray-200">Scrivi a</div>
                  <a
                    className="block text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-500 transition-colors text-sm md:text-md font-semibold"
                    href="mailto:info@casadalmine.it"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@casadalmine.it
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DESKTOP MENU */}
      <div className="hidden sm:block bg-blue-900 dark:bg-gray-700">
        <div className="max-w-7xl mx-auto px-6 flex items-center justify-center md:justify-start">
          <Link to="/" className="px-4 py-3 text-white hover:bg-black hover:bg-opacity-30 transition-colors">
            Home
          </Link>
          <Link to="/agenzia" className="px-4 py-3 text-white hover:bg-black hover:bg-opacity-30 transition-colors">
            Agenzia
          </Link>
          <Link
            to="/valuta-il-tuo-immobile"
            className="px-4 py-3 text-white hover:bg-black hover:bg-opacity-30 transition-colors"
          >
            Valuta la tua casa
          </Link>
          {servizi && servizi.length ? (
            <Popover className="relative z-10">
              <Popover.Button className="flex items-center space-x-1 px-4 py-3 text-white hover:bg-black hover:bg-opacity-30 transition-colors">
                <span>Servizi</span>
                <VscChevronDown />
              </Popover.Button>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute flex flex-col space-y-2 mt-2 py-3 px-6 z-10 bg-white shadow-xl w-max border rounded-md">
                  {servizi.map((servizio) => (
                    <Link
                      key={servizio.value}
                      className="block text-sm font-medium text-gray-600 hover:text-blue-900 transition-colors"
                      to={`/servizi/${servizio.value}`}
                    >
                      {servizio.label}
                    </Link>
                  ))}
                </Popover.Panel>
              </Transition>
            </Popover>
          ) : (
            <Link to="/servizi" className="px-4 py-3 text-white hover:bg-black hover:bg-opacity-30 transition-colors">
              Servizi
            </Link>
          )}
          <Link to="/contatti" className="px-4 py-3 text-white hover:bg-black hover:bg-opacity-30 transition-colors">
            Contatti
          </Link>
        </div>
      </div>
    </header>
  )
}

export default Header
